import * as React from 'react'
import { getImage } from "gatsby-plugin-image"
import { BgImage } from 'gbimage-bridge';

import { headerBlur, headerContainer, headerChildren } from './header-banner.module.css'


const HeaderBanner = ({ imageFile, children }) => {

    const image = getImage(imageFile)

    return (

        <div className={headerContainer}>
            <BgImage image={image} className={headerBlur}>
            </BgImage>
            <div className={`text-white ${headerChildren}`}>
                {children}
            </div>
        </div>

    )
}
export default HeaderBanner