import * as React from 'react'
import { Link } from 'gatsby'
import { Card, Button, Col } from 'react-bootstrap'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { animate } from '../styles/common.module.css'
import { contentCard, contentCardBody, contentCardButton, contentCardText, cardWrapper } from './card.module.css'


const ContentCard = ({ cardTitle, cardDesc, cardLink, cardImg, buttonText, externLink }) => {

    const image = getImage(cardImg)

    return (
        <Col className={` ${cardWrapper} ${animate}`}>
            <Card className={`shadow h-100 rounded ${contentCard}`}>
                {
                    externLink
                    ?
                    <a href={cardLink}>
                        <Card.Img as={GatsbyImage} image={image} alt="" variant="top" className={`rounded-top`} />
                    </a>
                    :
                    <Link to={cardLink}>
                        <Card.Img as={GatsbyImage} image={image} alt="" variant="top" className={`rounded-top`} />
                    </Link>
                }
                

                <Card.Body className={`text-center ${contentCardBody}`}>

                    <Card.Title as="h3">{cardTitle}</Card.Title>
                    <Card.Text className={contentCardText}>{cardDesc}</Card.Text>

                    {
                        externLink 
                        ?
                        <Button href={cardLink} className={`${contentCardButton}`}>{buttonText}</Button>
                        :
                        <Button as={Link} to={cardLink} className={`${contentCardButton}`}>{buttonText}</Button>
                    }
                </Card.Body>
            </Card>
        </Col>
    )
}
export default ContentCard