import * as React from 'react'
import { graphql } from 'gatsby'
import { Container, Row } from 'react-bootstrap'

import ReactMarkdown from 'react-markdown'

import MainLayout from '../../components/main-layout'
import Seo from '../../components/seo'
import HeaderBanner from '../../components/header-banner'
import HeaderBlock from '../../components/header-block'
import ContentCard from '../../components/card'

import { mainRow, animate } from '../../styles/common.module.css'


const CategoryViewPage = ({ data }) => {

    return (
        <MainLayout pageTitle={data.strapiCategory.name}>

            <Seo title={data.strapiCategory.name} description={data.strapiCategory.pagedescription} imageUrl={data.strapiCategory.cover.localFile.childImageSharp.fixed.src}></Seo>

            <HeaderBanner imageFile={data.strapiCategory.cover.localFile} />

            <Container>
                <HeaderBlock header={data.strapiCategory.name}>
                    <ReactMarkdown children={data.strapiCategory.pagedescription} />
                </HeaderBlock>

                <Row xs={1} md={2} className={`g-4 justify-content-center ${animate} ${mainRow}`}>
                    {
                        data.strapiCategory.projects.sort((a, b) => a.categorypriority - b.categorypriority).map(project => (
                            <ContentCard key={project.slug} cardTitle={project.boardname} cardDesc={project.slogan} cardLink={`/projects/${project.slug}`} cardImg={project.cover.localFile} buttonText="More Info"></ContentCard>
                        ))
                    }
                </Row>
            </Container>

        </MainLayout>
    )
}

export const query = graphql`
query ($id: String) {
    strapiCategory(id: {eq: $id}) {
      name
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1.618
              transformOptions: {fit: COVER, cropFocus: CENTER}
              placeholder: BLURRED
              width: 750
              quality: 90
            )
            fixed(width: 800, toFormat: JPG, quality: 95) {
                src
            }
          }
          url
        }
      }
      projects {
        boardname
        categorypriority
        slug
        slogan
        cover {
          localFile {
            childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.618
                  transformOptions: {fit: COVER, cropFocus: CENTER}
                  placeholder: BLURRED
                  width: 650
                  quality: 100
                )
              }
          }
        }
      }
      pagedescription
    }
  }
  
`

export default CategoryViewPage