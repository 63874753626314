import * as React from 'react'
import { Container } from 'react-bootstrap'

import { headerBlock } from './header-block.module.css'


const HeaderBlock = ({ header, children }) => {

    return (
        <Container className={`text-center ${headerBlock}`}>
            <h1>{header}</h1>
            {children}
        </Container>

    )
}
export default HeaderBlock